.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
